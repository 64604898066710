import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <nav>
        <ul className="footer-nav-links">
          <li>
            <Link to="/">HOME</Link>
          </li>
          <p>|</p>
          <li>
            <Link to="/location">LOCATION</Link>
          </li>
          <p>|</p>
          <li>
            <Link to="/rooms">ROOMS</Link>
          </li>
          <p>|</p>
          <li>
            <Link to="/motel-photos">MOTEL PHOTOS</Link>
          </li>
          <p>|</p>
          <li>
            <Link to="/sunset-photos">SUNSET PHOTOS</Link>
          </li>
          <p>|</p>
          <li>
            <Link to="/area-photos">AREA PHOTOS</Link>
          </li>
          <p>|</p>
          <li>
            <Link to="/local-links">LOCAL LINKS</Link>
          </li>
          <p>|</p>
          <li>
            <Link to="/contact">CONTACT</Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
