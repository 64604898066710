import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import heroImage from "../../Images/burtons-cape-breton-header-photo.jpg";
import "./Header.css";
import { Spin as Hamburger } from "hamburger-react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header>
      <div className="hero">
        <img src={heroImage} alt="Cape Breton" className="hero-image" />
      </div>
      <div className="hamburger">
        <Hamburger size="26" color="#f0f0f0" toggled={isMenuOpen} toggle={() => setIsMenuOpen(!isMenuOpen)} />
      </div>
      <nav>
        <ul className={"nav-links " + (isMenuOpen ? "open" : "")}>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
              }}
              to="/"
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
              }}
              to="/location"
            >
              LOCATION
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
              }}
              to="/rooms"
            >
              ROOMS
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
              }}
              to="/motel-photos"
            >
              MOTEL PHOTOS
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
              }}
              to="/sunset-photos"
            >
              SUNSET PHOTOS
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
              }}
              to="/area-photos"
            >
              AREA PHOTOS
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
              }}
              to="/local-links"
            >
              LOCAL LINKS
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
              }}
              to="/contact"
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
