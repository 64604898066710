import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Location from "./components/Location/Location";
import Rooms from "./components/Rooms/Rooms";
import MotelPhotos from "./components/MotelPhotos/MotelPhotos";
import SunsetPhotos from "./components/SunsetPhotos/SunsetPhotos";
import AreaPhotos from "./components/AreaPhotos/AreaPhotos";
import LocalLinks from "./components/LocalLinks/LocalLinks";
import Contact from "./components/Contact/Contact";
import ConditionalContent from "./components/ConditionalContent";
import Header from "./components/Header/Header";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Testimonials from "./components/Testimonials/Testimonials";

function App() {
  return (
    <div className="app">
      <HashRouter>
        <ConditionalContent />
        <div className="main page-container">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/location" element={<Location />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/motel-photos" element={<MotelPhotos />} />
            <Route path="/sunset-photos" element={<SunsetPhotos />} />
            <Route path="/area-photos" element={<AreaPhotos />} />
            <Route path="/local-links" element={<LocalLinks />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/testimonials" element={<Testimonials />} />
          </Routes>
          <Footer />
        </div>
        <p className="footer-copyright">Copyright © 2024 to present BurtonsOasisMotel.ca | Designed by Nicole Herritt</p>
      </HashRouter>
    </div>
  );
}

export default App;
