import React from "react";
import photo1 from "../../Images/burtonsunsetoasisgoldenbuilding.jpg";
import photo2 from "../../Images/burtonsunsetoasisgoldenlight.jpg";
import photo3 from "../../Images/burtons-sunset-small-5.jpg";
import photo4 from "../../Images/burtons-sunset-small-1.jpg";
import photo5 from "../../Images/burtons-sunset-small-4.jpg";
import photo6 from "../../Images/burtons-sunset-small-2.jpg";
import photo7 from "../../Images/burtons-sunset-small-6.jpg";
import photo8 from "../../Images/burtons-sunset-small-3.jpg";
import photo9 from "../../Images/burtonssunsetoasissunsetshadow.jpeg";
import "./SunsetPhotos.css";

const SunsetPhotos = () => {
  return (
    <div className="page-padding sunset-page-container">
      <h1 className="sunset-title">Sunset Photos</h1>
      <p className="sunset-photo-intro">
        Burton's Sunset Oasis is perfectly situated for you to soak up the sweeping unspoilt landscape and the incredibly beautiful sunsets. Your
        suite has a million dollar view overlooking where the majestic Cape Breton mountains meet the wild sea of the Cabot Strait. Here are just a
        few stunning examples captured of our sunsets. Click on each image to enjoy a larger version.
      </p>
      <div className="sunset-photos">
        <img src={photo1} alt="motel photo" />
        <img src={photo2} alt="motel photo" />
        <img src={photo3} alt="motel photo" />
        <img src={photo4} alt="motel photo" />
        <img src={photo5} alt="motel photo" />
        <img src={photo6} alt="motel photo" />
        <img src={photo7} alt="motel photo" />
        <img src={photo8} alt="motel photo" />
        <img src={photo9} alt="motel photo" />
      </div>
    </div>
  );
};

export default SunsetPhotos;
