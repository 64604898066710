// ConditionalContent.js
import { useLocation } from "react-router-dom";

function ConditionalContent() {
  const location = useLocation();

  if (location.pathname === "/") {
    return (
      <div className="home-top-text">
        Burton's Sunset Oasis Motel - Cabot Trail Accommodation along the Cabot Trail, in Bay St. Lawrence, Cape Breton, Nova Scotia.
      </div>
    );
  }

  // Return null or any default content for other routes
  return null;
}

export default ConditionalContent;
