import React from "react";
import oceanImage from "../../Images/testimonials-sunset-photo.jpg";
import "./Testimonials.css";
const Testimonials = () => {
  return (
    <div className="page-padding test-page">
      <section className="testimonials-page-section-one">
        <article className="testimonials-content">
          <h1>Testimonials for Burtons Sunset Oasis Motel</h1>
          <p>Read what visitors have to say about their stay at Burton's Sunset Oasis Motel.</p>
          <h2>The best hospitality and room of the trip.</h2>
          <p>J.F. Gonier, Quebe</p>
          <h2>Best Motel we`ve stayed, too bad we have to go home.</h2>
          <p>Sara, Peter, Tony, Emo Scott....Scotland</p>
          <h2>Amazing sunsets, enjoyed the bon fire and local celtic fiddle music.</h2>
          <p>Leveys, Austin,Tx</p>
          <h2>You beautiful spot.</h2>
          <p>Polly&Gerry, Australia</p>
          <h2>
            The most gracious host of all. In Heartfelt Appreciation for our wonderful holidays at the "End of the World" This Most Heavenly Oasis of
            all.
          </h2>
          <p>signed The Kavesh Brothers, New York</p>
        </article>
        <img src={oceanImage} alt="ocean" />
      </section>
    </div>
  );
};

export default Testimonials;
