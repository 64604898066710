import React from "react";
import roomImage from "../../Images/bedroom.jpg";
import diningRoomImage from "../../Images/dining-room.jpg";
import kitchenImage from "../../Images/kitchenette.jpg";
import "./Rooms.css";

const Rooms = () => {
  return (
    <div className="page-padding">
      <h1>Motel Rooms</h1>
      <section className="room-page-section-one">
        <div>
          <h2>Modern Comfort</h2>
          <p>Burton's Sunset Oasis Motel has five modern, comfortable suites available from May 1st to October 31st.</p>
        </div>
        <img className="room-images" src={kitchenImage} />
      </section>
      <section className="room-page-section-two">
        <article>
          <h2>Amenties include</h2>
          <ul>
            <li>High speed wireless internet - WIFI</li>
            <li>Flatscreen TV</li>
            <li>Full efficiency kitchenette - stovetop, fridge and microwave</li>
            <li>Wheelchair assessibility</li>
            <li>Free Parking - Drive-up Units</li>
            <li>Smoke free building</li>
            <li>Plug-in for Electric Vehicle</li>
            <li>Laundry service and groceries in Neil's Harbour (about 30 minutes away)</li>
          </ul>
        </article>
        <img className="room-images" src={diningRoomImage} />
      </section>
      <section className="room-page-section-three">
        <article className="rate-list">
          <h2>Rates</h2>
          <ul>
            <li className="li-flex">
              <div>
                <h3>Room 1 (Two Queen Beds)</h3>
                <p>2 to 4 people</p>
              </div>
              <p>$154.50</p>
            </li>
            <li className="li-flex">
              <div>
                <h3>Room 2 Studio (Two Queen Beds)</h3>
                <p>2 to 4 people</p>
              </div>
              <p>$154.50</p>
            </li>
            <li className="li-flex">
              <div>
                <h3>Room 3 (Two Queen Beds)</h3>
                <p>2 to 4 people</p>
              </div>
              <p>$154.50</p>
            </li>
            <li className="li-flex">
              <div>
                <h3>Room 4 Studio (Two Queen Beds)</h3>
                <p>2 to 4 people</p>
              </div>
              <p>$154.50</p>
            </li>
            <li className="li-flex">
              <div>
                <h3>Room 5 Deluxe Double (Queen Bed) Wheelchair Accessible</h3>
                <p>2 people</p>
              </div>
              <p>$154.50</p>
            </li>
            <p>* Prices do not include taxes.</p>
          </ul>
        </article>
        <img className="room-images" src={roomImage} />
      </section>
      <section className="room-page-section-four">
        <p>For Reservations Call: (902) 383-2666</p>
        <h1>Policies</h1>
        <ul>
          <li>All rooms are non-smoking.</li>
          <li>Pets are not permitted.</li>
          <li>Prices are in Canadian Dollars.</li>
          <li>Sales tax is not included.</li>
          <li>Cancellation policy: 72 hrs notice required.</li>
        </ul>
      </section>
    </div>
  );
};

export default Rooms;
