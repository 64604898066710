import React from "react";
import link1 from "../../Images/links/island-links-off.jpg";
import link2 from "../../Images/links/activities-off.jpg";
import link3 from "../../Images/links/places-to-eat-off.jpg";
import link4 from "../../Images/links/gift-stores-off.jpg";
import link5 from "../../Images/links/groceries-off.jpg";
import "./LocalLinks.css";

const LocalLinks = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="local-links-container">
      <section className="local-link-intro">
        <h1>Local Links and Amenities for the Top of Cape Breton Island</h1>
        <p>There is lots to see and do in the area around Burton's Sunset Oasis Motel.</p>
      </section>
      <section className="img-section">
        <img src={link1} alt="North Cape Breton Links" onClick={() => scrollToSection("northCapeBretonLinks")} style={{ cursor: "pointer" }} />
        <img src={link2} alt="Outdoor Activities" onClick={() => scrollToSection("outdoorActivities")} style={{ cursor: "pointer" }} />
        <img src={link3} alt="Places to Eat" onClick={() => scrollToSection("placesToEat")} style={{ cursor: "pointer" }} />
        <img src={link4} alt="Gift Stores" onClick={() => scrollToSection("giftStores")} style={{ cursor: "pointer" }} />
        <img src={link5} alt="Grocery Stores" onClick={() => scrollToSection("groceryStores")} style={{ cursor: "pointer" }} />
      </section>
      <section id="northCapeBretonLinks">
        <h2>North Cape Breton Links</h2>
        <a
          className="website-links"
          href="https://www.theweathernetwork.com/ca/marine/nova-scotia/bay-st-lawrence"
          target="blank"
          rel="Local Weather"
        >
          Local Weather
        </a>
        <h3>Bay St. Lawrence Community Centre</h3>
        <a className="website-links" href="https://www.baystlawrence.ca/" target="blank" rel="Accommodations">
          https://www.baystlawrence.ca
        </a>
        <h3>Victoria County Tourism</h3>
        <div className="flex-wrapper">
          <a
            className="website-links"
            href="https://victoriacounty.com/services/municipal-departments/tourism-recreation/"
            target="blank"
            rel="Accommodations"
          >
            https://victoriacounty.com/services/municipal-departments/tourism-recreation
          </a>
        </div>
        <h3>Bay St Lawrence</h3>
        <a className="website-links" href="https://www.baystlawrence.ca/bay-st-lawrence/" target="blank" rel="Accommodations">
          https://www.baystlawrence.ca/bay-st-lawrence
        </a>
        <h3>The Cabot Trail</h3>
        <a className="website-links" href="https://www.cbisland.com/tourist-attractions/cabot-trail/" target="blank" rel="Accommodations">
          https://www.cbisland.com/tourist-attractions/cabot-trail
        </a>
      </section>

      <section id="outdoorActivities">
        <h2>Outdoor Activities</h2>

        <h3>Eagle North Canoe and Kayak</h3>
        <div className="flex-wrapper">
          <a className="website-links" href="https://www.cabottrailoutdooradventures.com/" target="blank" rel="Accommodations">
            https://www.cabottrailoutdooradventures.com
          </a>
        </div>
        <h3>Sea Spray Outdoor Adventures</h3>
        <div className="flex-wrapper">
          <a className="website-links" href="http://www.cabot-trail-outdoors.com/" target="blank" rel="Accommodations">
            https://victoriacounty.com/services/municipal-departments/tourism-recreation
          </a>
        </div>
        <h3>Capt. Cox's Whale and Bird Watch</h3>
        <a
          className="website-links"
          href="https://www.novascotia.com/see-do/tours/capt-coxs-whale-and-bird-watch/2334"
          target="blank"
          rel="Accommodations"
        >
          https://www.novascotia.com/see-do/tours/capt-coxs-whale-and-bird-watch/2334
        </a>
        <h3>Oshan Whale Cruise</h3>
        <a className="website-links" href="https://oshan.ca/" target="blank" rel="Accommodations">
          https://oshan.ca
        </a>
        <h3>Cape Breton Highlands Links Golf</h3>
        <a className="website-links" href="https://golfcapebretonhighlands.ca/" target="blank" rel="Accommodations">
          https://golfcapebretonhighlands.ca
        </a>
        <h3>Le Portage Golf Club</h3>
        <a className="website-links" href="https://leportagegolfclub.com/" target="blank" rel="Accommodations">
          https://leportagegolfclub.com
        </a>
      </section>

      <section id="placesToEat">
        <h2>Places to Eat</h2>

        <h3>Chowder House</h3>
        <p>
          Eat in a picturesque setting with ocean and cliffs on three sides, full menu, fresh seafood and our famous seafood chowder, open June thru
          Sept.
        </p>

        <h3>Lobsterpalooza</h3>
        <a
          className="website-links"
          href="https://www.tripadvisor.ca/ShowTopic-g154972-i830-k1034889-Lobsterpalooza-Cape_Breton_Island_Nova_Scotia.html"
          target="blank"
          rel="Accommodations"
        >
          https://www.tripadvisor.ca/ShowTopic-g154972-i830-k1034889-Lobsterpalooza-Cape_Breton_Island_Nova_Scotia.html
        </a>
        <h3>Angie’s Family Restaurant ( licensed )</h3>
        <p>
          Angie’s is the only licensed air conditioned restaurant in Cape North offering a full menu including traditional favorites, Pizza, daily
          specials and shell fish.
        </p>

        <h3>Meat Cove Chowder Hut</h3>
        <p>
          Picture perfect setting, eat in or on our deck overlooking the ocean, Friendly service, full menu, Specialty is sea food and wonderful
          chowder.
        </p>

        <h3>The Bounty Takeout</h3>
        <p>
          Our specialty is great food, and great prices, Seafood at its best in a picnic style setting, Overlooking Dingwall Harbor, open all summer
        </p>

        <h3>Aspy Bay Oysters</h3>
        <a className="website-links" href="https://campingcapebreton.com/" target="blank" rel="Accommodations">
          https://campingcapebreton.com
        </a>

        <h3>THE BAY CAFE</h3>
        <a className="website-links" href="http://%20www.baystlawrence.ca/" target="blank" rel="Accommodations">
          http://%20www.baystlawrence.ca
        </a>
        <p>Located inside the BSL Community Center we offer a menu of local seafood and baked items. Eat in or take out </p>
        <p>OPEN YEAR ROUND</p>
      </section>

      <section id="groceryStores">
        <h2>GROCERY STORES</h2>

        <h3>Victoria Co op Fisheries</h3>
        <a className="website-links" href="" target="blank" rel="Accommodations"></a>
        <p>Coming Soon!</p>

        <h3>Neil's Harbor Coop</h3>
        <h3>Cabot Trail Food Mart</h3>
        <div className="flex-wrapper">
          <a className="website-links" href="https://www.facebook.com/cabottrailfoodmarket/" target="blank" rel="Accommodations">
            https://www.facebook.com/cabottrailfoodmarket
          </a>
        </div>
      </section>

      <section id="giftStores">
        <h2>GIFT STORES</h2>

        <h3>Cranberry Patch Fine Arts & Gifts </h3>
        <a className="website-links" href="http://www.asfec.org/" target="blank" rel="Accommodations">
          http://www.asfec.org
        </a>
        <h3>Arts North</h3>
        <a className="website-links" href="https://arts-north.com/" target="blank" rel="Accommodations">
          https://arts-north.com/
        </a>
        <h3>Country Crafts & Pottery Outlet</h3>
        <div className="flex-wrapper">
          <a className="website-links" href="https://www.facebook.com/CountryCraftsPotteryOutlet/" target="blank" rel="Accommodations">
            https://www.facebook.com/CountryCraftsPotteryOutlet/
          </a>
        </div>
        <h3>T&R Craft's</h3>
        <h3>Tartans and Treasures</h3>
        <a className="website-links" href="https://tartansandtreasures.ca/" target="blank" rel="Accommodations">
          https://tartansandtreasures.ca
        </a>
      </section>
    </div>
  );
};

export default LocalLinks;
