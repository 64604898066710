import React from "react";
import photo1 from "../../Images/area-small-3.jpg";
import photo2 from "../../Images/area-small-5.jpg";
import photo3 from "../../Images/area-small-2-whale.jpg";
import photo4 from "../../Images/area-small-6.jpg";
import photo5 from "../../Images/area-small-4.jpg";
import photo6 from "../../Images/area-small-1-eagle.jpg";
import "./AreaPhotos.css";

const AreaPhotos = () => {
  return (
    <div className="page-padding area-page-container">
      <h1>Bay St. Lawrence Area Photos</h1>
      <p className="area-photo-intro">
        Burton's Sunset Oasis Motel is in the beautiful North Cape Breton area, just outside of Bay St. Lawrence, along the Cabot Trail. See below for
        photos of the areas, and click on each to see a larger version.
      </p>
      <div className="area-photos">
        <img src={photo1} alt="area photo" />
        <img src={photo2} alt="area photo" />
        <img src={photo3} alt="area photo" />
        <img src={photo4} alt="area photo" />
        <img src={photo5} alt="area photo" />
        <img src={photo6} alt="area photo" />
      </div>
    </div>
  );
};

export default AreaPhotos;
