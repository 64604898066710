import React from "react";
import "./Location.css";
import hikingImage from "../../Images/view-of-the-valley.jpg";
import mapImage from "../../Images/Screenshot 2024-04-12 113255.png";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "400px",
};
const center = {
  lat: 47.0034188,
  lng: -60.4585357,
};

const Location = () => {
  return (
    <div className="page-padding">
      <h1>Location of Burton's Sunset Oasis Motel</h1>
      <section className="location-page-section-one">
        <img src={hikingImage} alt="people hiking down a mountain to the ocean bellow" />
        <article>
          <p>
            Burton`s Sunset Oasis Motel is located in Bay St. Lawrence on the tip of Cape Breton Island, in Nova Scotia, Canada. Bay St. Lawrence is a
            15 minute drive north from the world famous Cabot Trail.
          </p>
          <img src={mapImage} alt="map of the location of Burton's Sunset Oasis" />
        </article>
      </section>
      <section>
        <article>
          <h2>Directions</h2>
          <p>
            Follow the link on the map below to get driving directions to Burton's Sunset Oasis from your location. See below for more details and
            ferry information
          </p>
        </article>
        <article>
          <h3>Travelling from Sydney</h3>
          <p>
            Take the Trans Canada 105 Highway to Exit 12 Englishtown Ferry. If the lights are flashing at this intersection, the ferry is not running.
            When in operation, the fee to cross the ferry is $5 one way. If the ferry is not in operation, continue to exit 11 St. Ann`s. Both exits
            will bring you to the world famous Cabot Trail.
          </p>
          <p>
            You will drive for approximately 2 hours, taking in the mountains and ocean and part of the Highlands National Park. Turn right onto the
            Bay St. Lawrence Road in Cape North and follow it for approximately 15 min. You will travel through most of the community of Bay St.
            Lawrence before turning right on Money Point Rd. - Burton`s Sunset Oasis is a 1 min. drive up Money Point Rd. turning left at 105 Money
            Point Rd.
          </p>
        </article>
        <article className="location-page-section-two-article-three">
          <div className="google-map">
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
                {/* Child components, like markers, info windows, etc. */}
              </GoogleMap>
            </LoadScript>
          </div>
          <div>
            <h3>Travelling from Halifax, and other mainland locations.</h3>
            <p>
              Follow the Trans Canada East to Cape Breton. Upon reaching the Canso Causeway in Port Hastins, continue on the Trans Canada 105 Highway
              to Exit 11, St Ann`s or continue to Exit 12 Englishtown Ferry to shoren your trip be approximately 30 min. If the lights are flashing at
              the St. Ann`s exit, the ferry is not running. When in operation, the fee to cross the ferry is $5 one way. Both exits will bring you to
              the world famous Cabot Trail.
            </p>
            <p>
              You will drive for approximately 2.5 hours from this point, taking in the mountains and ocean and through part of the Highlands National
              Park. Turn right onto the bay St. lawrence Road in Cape North and follow it for approximately 15 min. You will travel through most of
              the community of Bay St. lawrence before turning right on Money Point Rd. -Burton`s Sunset Oasis is a 1 min. drive up Money Point Rd.
              turning left at 105 Money Point Rd.
            </p>
          </div>
        </article>
      </section>
    </div>
  );
};

export default Location;
