import React from "react";
import photo1 from "../../Images/motel-small-1.jpg";
import photo2 from "../../Images/motel-small-6.jpg";
import photo3 from "../../Images/motel-small-3.jpg";
import photo4 from "../../Images/motel-small-4.jpg";
import photo5 from "../../Images/motel-small-5.jpg";
import photo6 from "../../Images/motel-small-2.jpg";
import "./MotelPhotos.css";

const MotelPhotos = () => {
  return (
    <div className="page-padding motel-page-container">
      <h1>Motel Photos</h1>
      <p className="motel-photos-intro">
        Relax and enjoy the spectacular views of the unspoilt North Cape Breton coastline. Stay for a getaway vacation, or stop by on your Cabot Trail
        tour. Click on each photo to see a larger version.
      </p>
      <div className="motel-photos">
        <img src={photo1} alt="motel photo" />
        <img src={photo2} alt="motel photo" />
        <img src={photo3} alt="motel photo" />
        <img src={photo4} alt="motel photo" />
        <img src={photo5} alt="motel photo" />
        <img src={photo6} alt="motel photo" />
      </div>
    </div>
  );
};

export default MotelPhotos;
