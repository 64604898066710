import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import motelImage from "../../Images/burtonsmotelcapebreton.png";
import testimonialImage from "../../Images/testimonials-button.jpg";
import sunsetImage from "../../Images/burtonssunsetoasissunsetshadow.jpeg";

const Home = () => {
  return (
    <main>
      <section className="home-page-section-one">
        <img src={motelImage} alt="motel with ocean view" />
        <article className="home-page-section-one-article">
          <h1>Experience the beauty of Cape Breton</h1>
          <p>
            Nestled on a slope at the end of the village of Bay St. Lawrence you will find Burton's Sunset Oasis Motel overlooking the beaming sunset
            as it disappears behind the rolling mountains to the west.
          </p>
          <p>
            It is quiet and peaceful atmosphere lets you prepare yourself to explore our hiking trails, local boat tours,or visit the waves and sandy
            beaches at Cabot Landing.
          </p>
        </article>
      </section>
      <section className="home-page-section-two">
        <article className="home-page-section-two-article">
          <p>The natural beauty of our fishing village encourages you to:</p>
          <div className="home-page-section-two-paragraph">Sit, Relax and Enjoy...</div>
        </article>
        <Link to="/testimonials" className="link-to-testimonials-page">
          <img src={testimonialImage} alt="testimonial button" />
        </Link>
      </section>
      <section className="home-page-section-three">
        <img src={sunsetImage} alt="sunset" />
      </section>
    </main>
  );
};

export default Home;
